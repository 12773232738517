import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Reconocimientos = ({ data }) => {
  const reconocimientos = data.strapi.reconocimiento.reconocimientos.map(x => (
    // <Img
    //   cloudName={"lagexpress"}
    //   imageName={`${x.provider_metadata.public_id}.jpg`}
    //   fluid={{
    //     maxWidth: "auto",
    //   }}
    //   urlParams={"r_20"}
    //   imgStyle={{
    //     height: "auto",
    //   }}
    // />
    <img
      data-sizes="auto"
      className="lazyload blur-up"
      alt=""
      src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/${x.provider_metadata.public_id}`}
      data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_20/${x.provider_metadata.public_id}`}
      style={{
        width: "100%",
      }}
    />
  ))

  return (
    <Layout>
      <GatsbySeo
        title="Reconocimientos"
        titleTemplate="%s | Eduardo Ceballos"
        description="Reconocimientos"
        canonical={`https://eduardoceballos.com/reconocimientos`}
        openGraph={{
          url: `https://eduardoceballos.com/reconocimientos`,
          title: `Reconocimientos`,
          description: `Reconocimientos`,
          images: [
            {
              url: "https://eduardoceballos.com/seo/reconocimientos.jpg",
              alt: "",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Reconocimientos</h1>
      <div className="reconocimientos-grid">{reconocimientos}</div>
    </Layout>
  )
}

export const query = graphql`
  {
    strapi {
      reconocimiento {
        reconocimientos {
          provider_metadata
        }
      }
    }
  }
`

export default Reconocimientos
